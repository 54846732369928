import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Image from '../../assets/banner_v2.svg';
import { ReactComponent as VersionsIcon } from '../../assets/versions.svg';
import {Link} from 'react-router-dom';

const useStyles = makeStyles({
    grid: {
        fontFamily: 'SourceSansPro-Regular',
        height: 320,
        backgroundImage: `url(${Image})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        backgroundSize: "cover"
    },
    body: {
        fontFamily: 'SourceSansPro-Regular',
        fontSize: 14,
    },
    tableContainer: {
        marginBottom: 40
    },
    releasesHeader: {
        textAlign: 'center',
        color: 'white'
    },
    containerBlock: {
        paddingTop: 105
    },
    h2: {
        fontFamily: 'Gotham-Book',
        fontSize: 32,
        letterSpacing: -1,
        color: 'white',
        padding: '0px 20px 10px 20px',
        textAlign: 'center'
    },
    table: {
        minWidth: 650,
        fontFamily: 'SourceSansPro-Regular'
    },
    link: {
        fontFamily: 'SourceSansPro-SemiBold',
        color: '#1f96a2',
        cursor: 'pointer'
    }
});

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#262626",
        color: theme.palette.common.white
    },
    body: {
        fontSize: 14,
        fontFamily: 'SourceSansPro-Regular'
    }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

export const ESignReleaseVersion = () => {
    const classes = useStyles();
    const [data, setData] = useState([]);

    useEffect(() => {
        fetch("/releases_esign.json")
          .then((res) => res.json())
          .then(
            (data) => {
              setData(data.releases);
            },
            (error) => {
              console.log(error);
            }
          );
      }, []);

    return (
        <div className={classes.grid}>
            <div className="returnToHome">
                <Link to={"/"} variant="outlined" size="large" className={classes.backButton}>&lt; Return to Homepage</Link>
            </div>
            <div className={ `container ${classes.containerBlock}` }>
                <div className={classes.releasesHeader}>
                    <VersionsIcon style={{height:'56',width:'56', fill: 'white'}} title="" />
                    <h2 className={classes.h2}>eSign - Release Versions</h2>
                </div>
                <div>
                    <TableContainer component={Paper}>
                        <Table aria-label="a dense table" size="small" className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell><b>Version Name</b></StyledTableCell>
                                    <StyledTableCell align="right"><b>Support Type</b></StyledTableCell>
                                    <StyledTableCell align="right"><b>Release Date</b></StyledTableCell>
                                    <StyledTableCell align="right"><b>End Of Life</b></StyledTableCell>
                                    <StyledTableCell align="left"><b>Active</b></StyledTableCell>
                                    <StyledTableCell align="center"><b>Release Notes</b></StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data && data.map((row) => (
                                    <StyledTableRow key={row.version}>
                                        <StyledTableCell>

                                            {row.supportType === "Hotfix" &&
                                                <i className="tabSpace">{row.version}</i>
                                            }
                                            {row.supportType !== "Hotfix" &&
                                                <b>{row.version}</b>
                                            }

                                        </StyledTableCell>
                                        <StyledTableCell align="right">{row.supportType}</StyledTableCell>
                                        <StyledTableCell align="right">{row.releaseDate}</StyledTableCell>
                                        <StyledTableCell align="right">
                                        {row.supportType === "Hotfix" &&
                                                <i className="tabSpace">{row.endOfLife}</i>
                                            }
                                            {row.supportType !== "Hotfix" &&
                                                <b>{row.endOfLife}</b>
                                            }
                                        </StyledTableCell>
                                        <StyledTableCell align="left">{row.state}</StyledTableCell>
                                        <StyledTableCell align="center">{row.link && <a target='_new' className={classes.link} href={row.link}>{row.releaseNotes}</a>}</StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
        </div>
    )
}
export default ESignReleaseVersion;