import React from 'react';
import Iframe from '../../components/Iframe';

export const ProductCatalogue = () => (

    <Iframe
        title="esign"
        src={process.env.REACT_APP_DOCUMENTATION_PRODUCT_CATALOG_URL}
    />

)
export default ProductCatalogue;