import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import constants from '../../main/constants';
import { ReactComponent as Icon } from '../../assets/icon-newsletter-line.svg';
import Image from '../../assets/banner_v2.svg';
import { Button } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    root: {
        textAlign: 'center'
    },
    background: {
        height: 320,
        backgroundImage: `url(${Image})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        backgroundSize: "cover"
    },
    icon: {
        paddingTop: 40
    },
    thanks: {
        fontFamily: 'Gotham-Bold',
        fontSize: 32,
        letterSpacing: -1,
        color: '#ffffff',
        marginTop: 20
    },
    message: {
        fontFamily: 'Gotham-Book',
        fontSize: 20,
        letterSpacing: -0.63,
        color: '#ffffff',
        marginTop: 20
    },
    back: {
        marginTop: 36
    },
    backLink: {
        textDecoration: 'none'
    },
    backButton: {
        color: '#ffffff',
        borderRadius: 20,
        textTransform: 'none',
        fontColor: 'Bariol-Bold',
        fontSize: 16,
        border: 'solid 1px #ffffff',
    }
}));

export const Subscription = ({ setPage }) => {
    const classes = useStyles();

    const onClick = () => {
        setPage(constants.START_PAGE);
    }

    return (
        <div className={classes.root}>
            <div className={classes.background}>
                <div className={classes.icon}><Icon /></div>
                <div className={classes.thanks}>Thanks for subscribing!</div>
                <div className={classes.message}>From now on, you will receive all the latest news and updates.</div>
                <div className={classes.back}>
                    <Button onClick={onClick} variant="outlined" size="large" className={classes.backButton}>Back to Homepage</Button>
                </div>
            </div>
        </div>
    );
}