import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    root: {
        height: '100%',
        width: '100%',
        position: 'fixed'
    },
    iframe: {
        display: 'block',
        border: 'none',
        height: '100%',
        width: '100%'
    }
}));

const Iframe = ({ title, src }) => {

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <iframe
                className={classes.iframe}
                id={title + '_iframe'}
                title={title}
                scrolling="YES"
                frameBorder="0"
                src={src}
            />
        </div>
    );

}

export default Iframe;