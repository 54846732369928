import React, { Fragment, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import queryString from 'query-string';
import constants from './constants';

import { Start as StartPage } from '../containers/Pages/Start';
// import { Documentation as DocumentationPage } from '../containers/Pages/Documentation';
// import Header from '../containers/Header';
// import { ReleasePlan as ReleasePlanPage } from '../containers/Pages/ReleasePlan';
// import { GetSupport as GetSupportPage } from '../containers/Pages/GetSupport';
import { Subscription as SubscribedPage } from '../containers/Pages/Subscription';
// import { DistroBuilder as DistroBuilderPage } from '../containers/Pages/DistroBuilder';
// import { Esign as EsignPage } from '../containers/Pages/Esign';
// import { OmniReleaseVersion as OmniReleaseVersionPage } from '../containers/Pages/OmniReleaseVersion';
// import { ESignReleaseVersion as ESignReleaseVersionPage } from '../containers/Pages/eSignReleaseVersion';
// import { Training as TrainingPage } from '../containers/Pages/Training';

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
        minHeight: 216
    },
    headerMargin: {
        marginTop: 48
    }
}));

const Main = () => {
    const [page, setPage] = useState(null);
    const queryParams = queryString.parse(window.location.search);
    const classes = useStyles();

    const renderStartPage = () => {
        const url = new URL(window.location);
        const params = url.searchParams;
        if (params.get('s') === "1") {
            params.delete('s');
            window.history.pushState({}, document.title, url);
        }

        return <StartPage page={page} setPage={setPage} />
    }

    const renderContent = () => {
        if (page !== constants.START_PAGE && queryParams.s === "1") {
            return (
                // Subscribed page
                <SubscribedPage setPage={setPage} />
            )
        }
        else {
            return (
                <Fragment>
                    {/* Start page */}
                    {(page === null || page === constants.START_PAGE) && renderStartPage()}
                </Fragment>
            )
        }
    }

    return (
        <div className={classes.root}>
        
            <div className={classes.headerMargin}>
                {renderContent()}
            </div>
        </div>
    )
}

export default Main;
