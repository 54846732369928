import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles(() => ({
    root: {
        height: 68,
        backgroundColor: '#f5f5f5',
        fontSize: 14,
        color: '#8e8e8e',
        marginTop: 80,
        padding: 20
    }
}));

const Footer = () => {
    const classes = useStyles();
    const year = new Date().getFullYear();

    return (
        <Grid container
            className={classes.root}
            alignContent="center"
            justify="center">
            Celfocus Products - Support Portal - {year}
        </Grid>
    );

}

export default Footer;