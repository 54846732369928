export default {
    START_PAGE: 'start_page',
    DOCUMENTATION_PAGE: 'documentation_page',
    RELEASE_PLAN_PAGE: 'release_plan_page',
    SETUP_ENVIRONMENT_PAGE: 'setup_environment_page',
    TUTORIALS_PAGE: 'tutorials_page',
    LEARNING_PATHS_PAGE: 'learning_paths_page',
    SUBSCRIPTION_PAGE: 'subscription_page',
    DISTRO_BUILDER_PAGE: 'distro_builder_page',
    GET_SUPPORT_PAGE: 'get_support_page',
    ESIGN_PAGE: 'esign_page',
    SEARCH_MAIN: 'SEARCH_MAIN',
    SEARCH_HEADER: 'SEARCH_HEADER',
    OMNI_PRODUCT_VERION: 'omni_product_version',
    ESIGN_PRODUCT_VERION: 'esign_product_version',
    TRAINING_PAGE: 'training_page'
}