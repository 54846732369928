import React from 'react';
import Iframe from '../../components/Iframe';

export const Esign = () => (

    <Iframe
        title="esign"
        src={process.env.REACT_APP_DOCUMENTATION_ESIGN_URL}
    />

)
export default Esign;