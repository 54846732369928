import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Link } from "@material-ui/core";

import Section from '../../components/Section';
import SectionHeader from '../../components/SectionHeader';
import SectionBody from '../../components/SectionBody';

import { ReactComponent as Icon } from '../../assets/feedback-new.svg';
import Image from '../../assets/feedback.svg';

const useStyles = makeStyles(() => ({
    content: {
        fontSize: 16
    },
    topText: {
        fontFamily: 'SourceSansPro-Regular',
        marginBottom: 20
    },
    links: {
        fontFamily: 'SourceSansPro-SemiBold',
    },
    listItem: {
        marginBottom: 20
    },
    link: {
        color: '#ed1c24'
    }
}));

const Feedback = () => {
    const classes = useStyles();

    return (
        <Section backgroundImage={Image}>
            <SectionHeader title="Give feedback">
                <Icon />
            </SectionHeader>
            <SectionBody>
                <Grid container className={classes.content}>
                    <Grid item xs={12} className={classes.topText}>
                        <div>Your feedback and ideas are very important!</div>
                        <div>Share it with us and help us improve our products.</div>
                    </Grid>
                    <ul className={classes.links}>
                        <li className={classes.listItem}>
                            <Link
                                href={process.env.REACT_APP_FEEDBACK_DIGITAL_JOURNEY_URL}
                                className="linkText"
                                underline='none'
                                target='_blank'
                                rel='noopener noreferrer'>
                                Omnichannel
                            </Link>
                        </li>
                        <li className={classes.listItem}>
                            <Link
                                href={process.env.REACT_APP_FEEDBACK_ESIGN_URL}
                                className="linkText"
                                underline='none'
                                target='_blank'
                                rel='noopener noreferrer'>
                                eSign
                            </Link>
                        </li>
                        <li className={classes.listItem}>
                            <Link
                                href={process.env.REACT_APP_FEEDBACK_ORDER_MANAGEMENT_URL}
                                className="linkText"
                                underline='none'
                                target='_blank'
                                rel='noopener noreferrer'>
                                Order Management
                            </Link>
                        </li>
                    </ul>
                </Grid>
            </SectionBody>
        </Section>
    );

}

export default Feedback;