import React from 'react';
import './App.css';
import { Switch, Route } from "react-router-dom";
import Main from './main/main';
import Header from './containers/Header';
import Training from './containers/Pages/Training'
import OmniReleaseVersion from './containers/Pages/OmniReleaseVersion';
import OrderManagementReleaseVersion from './containers/Pages/OrderManagementReleaseVersion';
import ESignReleaseVersion from './containers/Pages/eSignReleaseVersion';
import ProductCatalogueReleaseVersion from './containers/Pages/ProductCatalogueReleaseVersion';
import ProductCatalogue from './containers/Pages/ProductCatalogue';
import Esign from './containers/Pages/Esign';
import Documentation from './containers/Pages/Documentation';
import { DistroBuilder } from './containers/Pages/DistroBuilder';


function App() {
  return (
    
    <div>
          <Header/>

          <Switch>
            <Route exact path={"/"} component={Main} />
            <Route exact path={"/training"} component={Training} />
            <Route exact path={"/releaseversion/ordermanagement"} component={OrderManagementReleaseVersion} />
            <Route exact path={"/releaseversion/omni"} component={OmniReleaseVersion} />
            <Route exact path={"/releaseversion/esign"} component={ESignReleaseVersion} /> 
            <Route exact path={"/releaseversion/productcatalogue"} component={ProductCatalogueReleaseVersion} /> 
            <Route exact path={"/documentation/esign"} component={Esign} /> 
            <Route exact path={"/documentation/omni"} component={Documentation} />
            <Route exact path={"/documentation/productcatalogue"} component={ProductCatalogue} />
            <Route exact path={"/distrobuilder"} component={DistroBuilder} />
          </Switch>

    </div>
  );
}
//App.whyDidYouRender = true;

export default App;
