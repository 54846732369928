import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import Image from '../../assets/banner_training.svg';
import Timeline from '@material-ui/lab/Timeline';
import { TimelineItem as MuiTimelineItem } from '@material-ui/lab';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import Chip from "@material-ui/core/Chip";
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import { Avatar } from '@material-ui/core';
import PaperItem from '../../components/PaperItem';
import '../../components/PaperItem';
import { ReactComponent as BookIcon } from '../../assets/book-icon.svg';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
    grid: {
        height: 260,
        width: '100%',
        backgroundImage: `url(${Image})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        backgroundSize: "cover",
        textAlign: 'top'
    },

    trainingGrid: {
        marginTop: 40
    },

    trainingHeader: {
        textAlign: 'center',
        paddingTop: 50
    },

    containerBlock: {
        paddingTop: 80,
        marginBottom: 50,
        height: 150,
        justifyContent: 'flex-end !important',
        alignSelf: 'flex-end !important',
        alignItems: 'flex-end !important',
        textAlign: 'center',
    },
    h2: {
        fontFamily: 'Gotham-Book',
        fontWeight: 600,
        fontSize: 32,
        color: 'white',
        textAlign: 'center',

    },
    h3: {
        fontFamily: 'Gotham-Book',
        fontSize: 20,
        color: 'white',
        textAlign: 'center',

    },
    contentsToTheLeft: {
        justifyContent: 'left !important',
        alignItems: 'left !important',
        textJustify: 'left !important',
        textAlign: 'left !important',
    },
    missingOppositeContent: {

    },

    comingSoonChip: {
        backgroundColor: '#1f96a2',
        color: 'white',
        height: 16,
        fontSize: 10,
        paddingTop: 1,
        marginLeft: 4
    },
    redCircle: {
        backgroundColor: '#ff6859',
        width: '25px',
        height: '25px',
        fontFamily: 'SourceSansPro-SemiBold',
        fontSize: 'small'
    },
    redLine: {
        backgroundColor: '#ff6859',
    },
    marker: {
        position: 'relative',
        justifyContent: 'center',
    },
    floatingObject: {
        position: 'absolute',
        fill: '#ff6859',
        backgroundColor: '#F5f5f5'
    },
    floatingCircleFirst: {
        position: 'absolute',
        fill: '#ff6859',
        backgroundColor: '#F5f5f5',
        top: 0
    },
    floatingCircle: {
        position: 'absolute',
        fill: '#ff6859',
        backgroundColor: '#F5f5f5',
        top: 35
    },
    sectionName: {
        marginTop: -10,
        marginLeft: 20
    },
    sectionModule: {
        marginBottom: 20,
        marginLeft: 20
    },
    lastModule: {
        marginBottom: 40,
        marginLeft: 20
    }
});

const TimelineItem = withStyles({
    missingOppositeContent: {
        "&:before": {
            display: "none",

        }
    }
})(MuiTimelineItem);

export const Training = () => {
    const classes = useStyles();
    const [data, setData] = useState([]);
    var sectionCounter = 0;
    var moduleCounter = 0;

    /**
     * Return duration in "00h 00m" format
     * @param {number} durationMinutes 
     */
    function returnTime(durationMinutes) {

        if (durationMinutes === undefined) return undefined;

        var moduleDurationHours = Math.floor(durationMinutes / 60)
        var moduleDurationMinutes = durationMinutes % 60;
        var moduleDurationString = (moduleDurationHours >= 1 ? moduleDurationHours + "h " : "") + moduleDurationMinutes.toString().padStart(2, 0) + "m";
        return moduleDurationString;
    }

    useEffect(() => {

        fetch("/training.json")
            .then((res) => res.json())
            .then(
                (data) => {
                    setData(data);
                },
                (error) => {
                    console.log(error);
                }
            );
    }, []);

    return (
        <div className={classes.grid}>
            <div className="returnToHome">
                <Link to={"/"} variant="outlined" size="large" className={classes.backButton}>&lt; Return to Homepage</Link>
            </div>
            <div className={classes.trainingHeader}>
                <BookIcon style={{ height: '56', width: '56' }} />
                <h2 className="Training">Training</h2>
                <h3 className={classes.h3}>The place to understand and learn the basics of the product</h3>
            </div>
            <div className={classes.contentsToTheLeft}>
                <Grid container className={classes.trainingGrid}>
                    <Grid item xs="2"></Grid>
                    <Grid item xs="auto">
                        <Timeline className={classes.contentsToTheLeft}>
                            {data && data.map(function (section) {
                                // increment the section counter
                                if (section.available === undefined) {
                                    section.available = true;
                                }
                                sectionCounter++;
                                moduleCounter = 0;
                                // calculate total module duration
                                var totalDuration = 0;
                                for (var i = 0; i < section.modules.length; i++) {
                                    totalDuration += section.modules[i].duration ? section.modules[i].duration : 0;
                                }

                                // write the section header
                                return <div className="trainingSection" key={sectionCounter}><TimelineItem>
                                    <TimelineSeparator className={classes.marker}>
                                        <div className={classes.floatingCircleFirst}>
                                            <Avatar className={classes.redCircle}>
                                                {sectionCounter}
                                            </Avatar>
                                        </div>
                                        <TimelineConnector className={classes.redLine} />
                                    </TimelineSeparator>
                                    <TimelineContent>
                                        <div className={classes.sectionName}>
                                            <div className="Basics">{section.name}</div>
                                            <div className="modulesDescription">
                                                {section.modules.length} {section.modules.length > 1 ? "modules" : "module"}
                                                {section.available && totalDuration > 0 &&
                                                    <div><Chip size="small" className="paperChip" avatar={<WatchLaterIcon className="watchFace" style={{ fill: "white" }} />} label={returnTime(totalDuration)} /></div>
                                                }
                                                {!section.available &&
                                                    <Chip size="small" label="COMING SOON" className={classes.comingSoonChip} />
                                                }
                                            </div>
                                        </div>
                                    </TimelineContent>
                                </TimelineItem>
                                    {
                                        section.modules && section.modules.map(function (module) {
                                            if (module.available === undefined) {
                                                module.available = true;
                                            }
                                            moduleCounter++;
                                            var moduleKey = sectionCounter + "." + moduleCounter;
                                            var lastModuleClassName = moduleCounter === section.modules.length ? classes.lastModule : classes.sectionModule;

                                            return <TimelineItem key={moduleKey}>
                                                <TimelineSeparator className={classes.marker}>
                                                    <RadioButtonCheckedIcon className={classes.floatingObject} fontSize="small" />
                                                    <TimelineConnector className={classes.redLine}></TimelineConnector>
                                                </TimelineSeparator>
                                                <TimelineContent className={lastModuleClassName} >
                                                    <PaperItem
                                                        title={module.title}
                                                        description={module.description}
                                                        url={module.link}
                                                        active={section.available && module.available}
                                                        time={returnTime(module.duration)} />
                                                </TimelineContent>
                                            </TimelineItem>
                                        })
                                    }
                                </div>
                            })
                            }
                        </Timeline>
                    </Grid>
                </Grid>
            </div>

        </div>
    )
}
export default Training;
