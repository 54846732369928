import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from "@material-ui/core";
import { Link as LinkRouter } from 'react-router-dom';

import Section from '../../components/Section';
import SectionHeader from '../../components/SectionHeader';
import SectionBody from '../../components/SectionBody';

import { ReactComponent as Icon } from '../../assets/doc-new.svg';
import Image from '../../assets/doc.svg';
//import constants from '../../main/constants';

const useStyles = makeStyles(() => ({
    content: {
        fontFamily: 'SourceSansPro-Regular',
        fontSize: 16,
        marginBottom: 20
    },
    links: {
        fontFamily: 'SourceSansPro-SemiBold',
    },
    listItem: {
        marginBottom: 20
    },
    link: {
        color: '#ed1c24 !important',
        textDecoration: 'none !important',
        cursor: 'pointer !important',
        marginLeft: '20 !important',
        fontFamily: 'SourceSansPro-SemiBold'
    }
}));

const Documentation = ({ setPage }) => {
    const classes = useStyles();

    // const onClickDigitalJourney = () => {
    //     setPage(constants.DOCUMENTATION_PAGE);
    // }

    // const onClickEsign = () => {
    //     setPage(constants.ESIGN_PAGE);
    // }

    return (
        <Section backgroundImage={Image}>
            <SectionHeader title="Documentation">
                <Icon />
            </SectionHeader>
            <SectionBody>
                <Grid container className={classes.content}>
                    <Grid item xs={12}>
                        <div>The place where you can read all of the product documentation.</div>
                        <div>Includes guides for installation, sizing, hardening, support and maintenance for all of the product's microservices, apps and modules.</div>
                    </Grid>

                </Grid>
                <div>
                <div className="row" style={{ marginBottom: 20 }}>
                        <div className="col">
                            <li className="marginLeft">
                                <a href="/docs/omnichannel"  className="linkText" underline='none' rel='noopener noreferrer'>Omnichannel - Digital Journey</a> / <LinkRouter
                                className="linkText"
                                to={"/releaseversion/omni"}
                                underline='none'
                                rel='noopener noreferrer'>Release Versions</LinkRouter>
                            </li>
                        </div>
                    </div>

                    <div className="row" style={{ marginBottom: 20 }}>
                        <div className="col">
                            <li className="marginLeft"><LinkRouter
                                className="linkText"
                                to={"/documentation/esign"}
                                underline='none'
                                rel='noopener noreferrer'>
                                eSign
                            </LinkRouter> / <LinkRouter
                                className="linkText"
                                to={"/releaseversion/esign"}
                                underline='none'
                                rel='noopener noreferrer'>Release Versions</LinkRouter>
                            </li>

                        </div>
                    </div>

                    <div className="row" style={{ marginBottom: 20 }}>
                        <div className="col">
                            <li className="marginLeft"><a href="/docs/productcatalogue"  className="linkText" underline='none' rel='noopener noreferrer'>Product Catalogue</a> / <LinkRouter
                                className="linkText"
                                to={"/releaseversion/productcatalogue"}
                                underline='none'
                                rel='noopener noreferrer'>Release Versions</LinkRouter>
                            </li>
                        </div>
                    </div>

                    <div className="row" style={{ marginBottom: 20 }}>
                        <div className="col">
                            <li className="marginLeft"><a href="/docs/ordermanagement" className="linkText"
                                                          underline='none' rel='noopener noreferrer'>Order
                                Management</a> / <LinkRouter
                                className="linkText"
                                to={"/releaseversion/ordermanagement"}
                                underline='none'
                                rel='noopener noreferrer'>Release Versions</LinkRouter>
                            </li>
                        </div>
                    </div>

                </div>
            </SectionBody>
        </Section>

    );

}

export default Documentation;
