import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";

import Section from '../../components/Section';
import SectionHeader from '../../components/SectionHeader';
import SectionBody from '../../components/SectionBody';

import { ReactComponent as Icon } from '../../assets/newsletter-new.svg';

const useStyles = makeStyles(() => ({
    content: {
        fontFamily: 'SourceSansPro-Regular',
        fontSize: 16,
        marginTop: -24
    },
    frame: {
        borderRadius: 20
    },
    iframe: {
        //display: 'block',
        border: 'none',
        height: '100vh',
        width: '100%'
    }
}));

const Subscription = () => {
    const classes = useStyles();

    useEffect(() => {
        const div = document.createElement('div');
        div.className = "_form_3";

        const script = document.createElement('script');
        script.src = process.env.REACT_APP_SUBSCRIPTION_URL;
        script.async = true;
        script.onload = function () {
            // Remove branding
            let brandingEls = document.getElementsByClassName("_form-branding");
            if (brandingEls.length > 0) { brandingEls[0].remove(); }
        }

        const head = document.querySelector('head');
        if (head) head.appendChild(script);

        const el = document.getElementById('subscription');
        if (el) el.appendChild(div);

        return () => {
            if (el) el.removeChild(div);
            if (head) head.removeChild(script);
        }
    }, []);

    return (
        <Section className={classes.frame}>
            <SectionHeader title="Want to know more? Subscribe our email updates">
                <Icon />
            </SectionHeader>
            <SectionBody>
                <Grid container className={classes.content}>
                    <Grid item xs={12}>
                        <div id="subscription" className={classes.root} />
                    </Grid>
                </Grid>
            </SectionBody>
        </Section>
    );

}

export default Subscription;