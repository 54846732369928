import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Paper } from "@material-ui/core";

import Image from '../../assets/banner_v2.svg';
import constants from '../../main/constants';
import Search from '../../containers/Search/Search';
import Footer from '../../containers/Footer';

import GettingStarted from '../Sections/GettingStarted';
import Documentation from '../Sections/Documentation';
//import ReleasePlan from '../Sections/ReleasePlan';
import GetSupport from '../Sections/GetSupport';
import Feedback from '../Sections/Feedback';
import Subscription from '../Sections/Subscription';

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
        margin: 'auto',
        display: 'flex',
        outline: 0,
        position: 'relative',
        justifyContent: 'center',
        width: '100%'
    },
    grid: {
        height: 320,
        backgroundImage: `url(${Image})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        backgroundSize: "cover"
    },
    paper: {
        height: '100%',
        maxWidth: 500,
        width:450,
        borderRadius: 20
    },
    paperSubscription: {
        backgroundColor: '#f5f5f5',
        borderRadius: 20
    }
}));

export const Start = ({ page, setPage }) => {
    const classes = useStyles();

    return (
        <Fragment>
            <div className={classes.grid}>
                <Search type={constants.SEARCH_MAIN} page={page} setPage={setPage} />
                <Grid container className={classes.root} spacing={4}>
                    <Grid item xs={12}>
                        <Grid container justify="center" spacing={4}>
                            <Grid item>
                                <Paper className={classes.paper}>
                                    <GettingStarted setPage={setPage} />
                                </Paper>
                            </Grid>
                            <Grid item>
                                <Paper className={classes.paper}>
                                    <Documentation setPage={setPage} />
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container justify="center" spacing={4}>
                            <Grid item>
                                <Paper className={classes.paper}>
                                    <GetSupport setPage={setPage} />
                                </Paper>
                            </Grid>
                            <Grid item>
                                <Paper className={classes.paper}>
                                    <Feedback />
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* <Grid item xs={12} style={{ maxWidth: 1064 }}>
                        <Paper className={classes.paperSubscription}>
                            <Subscription setPage={setPage} />
                        </Paper>
                    </Grid> */}
                </Grid>
                <Footer />
            </div>
        </Fragment >
    );
}
