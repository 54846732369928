import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles(() => ({
    root: {
        marginBottom: 24
    },
    container: {
        alignItems: 'center'
    },
    title: {
        fontFamily: 'Bariol-Bold',
        fontSize: 24,
        marginLeft: 20,
        marginBottom: 6
    }
}));

const SectionHeader = ({ children, title }) => {

    const classes = useStyles();

    return (
        <Grid item xs={12} className={classes.root}>
            <Grid container className={classes.container}>
                <Grid item>
                    {children}
                </Grid>
                <Grid item className={classes.title}>
                    {title}
                </Grid>
            </Grid>
        </Grid>
    );

}

export default SectionHeader;