import React, { useState, useEffect, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MainSearch from './MainSearch';
import HeaderSearch from './HeaderSearch';
import regexifyString from 'regexify-string';
import { Paper } from "@material-ui/core";

import constants from '../../main/constants';

const useStyles = makeStyles(() => ({
    resultsSummary: {
        backgroundColor: '#f5f5f5',
        textAlign: 'right',
        padding: 7,
        paddingRight: 24,
        fontSize: 12,
        fontFamily: 'SourceSansPro-Regular',
        color: '#8e8e8e',
        borderTop: '1px solid #e0e0e0',
        borderBottom: '1px solid #e0e0e0'
    },
    renderOption: {
        borderBottom: '1px dotted gray',
        width: '100%',
        padding: 6
    },
    renderOptionTitle: {
        fontFamily: 'SourceSansPro-SemiBold',
        fontSize: 16,
        fontWeight: 600
    },
    renderOptionContent: {
        fontFamily: 'SourceSansPro-Regular',
        fontSize: 14,
        color: '#8e8e8e'
    },
    renderOptionHighlight: {
        fontWeight: 'bold',
        color: '#ed1c24'
    }
}));

const Search = ({ type, page, setPage }) => {
    const [value, setValue] = useState(0);
    const [result, setResult] = useState({ results: [], count: 0 });
    const classes = useStyles();

    useEffect(() => {
        const event = "message";
        const handler = (e) => {
            // Do we trust the sender of this message?
            //if (event.origin !== "http://example.com:8080")
            //  return;

            if (e.data.count !== undefined) {
                setResult({
                    results: e.data.results,
                    count: e.data.count
                });
            }
        }

        // initiate the event handler
        window.addEventListener(event, handler, false);

        // this will clean up the event every time the component is re-rendered
        return function cleanup() {
            window.removeEventListener(event, handler);
        };
    }, [value]);

    useEffect(() => {
        const search = (query) => {
            postMessage({ type: "search", query: query });
        }

        const timer = setTimeout(() => search(value), 1000);
        return () => clearTimeout(timer);
    }, [value]);

    const postMessage = (message) => {
        const docIframe = document.getElementById('documentation_iframe');
        if (docIframe && docIframe.contentWindow) {
            docIframe.contentWindow.postMessage(message, process.env.REACT_APP_HOST);
        }
    }

    const handleOnChange = (event) => {
        if (event && event.target) {
            let val = event.target.value || "";
            if (val.length < 3) {
                if (value === "" || result.results.length > 0) {
                    setResult({
                        results: [],
                        count: val.length
                    })
                }
            }
            else {
                setValue(val);
            }
        }
    }

    const paperComponent = ({ children, ...other }) => (
        children && children.some(num => num !== null) &&
        result.results && result.results.length > 0 &&
        <Paper style={{ minWidth: 600 }} {...other}>
            <div className={classes.resultsSummary}>Found results in {result.count} document(s)</div>
            {children}
        </Paper>
    )

    const renderOption = option => {
        return (
            <div className={classes.renderOption}>
                <div className={classes.renderOptionTitle}>{option.title}</div>
                <div className={classes.renderOptionContent}>{highlightContent(option.content, value)}</div>
            </div>
        )
    }

    const highlightContent = (content, value) => {
        return regexifyString({
            pattern: new RegExp('(' + value + ')', 'ig'),
            decorator: (match, index) => {
                return (<span key={match + '_' + index} className={classes.renderOptionHighlight}>{match}</span>);
            },
            input: content,
        });
    }

    const handleOnSelectFromSearch = (ev, value) => {
        if (value) {
            postMessage({ type: "showDoc", key: value.key });
            
            if (page !== constants.DOCUMENTATION_PAGE)
                setPage(constants.DOCUMENTATION_PAGE);
        }
    }

    return (
        <Fragment>
            { type === constants.SEARCH_MAIN &&
                <MainSearch
                    results={result.results}
                    handleOnChange={handleOnChange}
                    handleOnSelectFromSearch={handleOnSelectFromSearch}
                    paperComponent={paperComponent}
                    renderOption={renderOption}
                />
            }
            { type === constants.SEARCH_HEADER &&
                <HeaderSearch
                    results={result.results}
                    handleOnChange={handleOnChange}
                    handleOnSelectFromSearch={handleOnSelectFromSearch}
                    paperComponent={paperComponent}
                    renderOption={renderOption}
                />
            }
        </Fragment>
    );

}

export default Search;