import React from 'react';
import Grid from "@material-ui/core/Grid";

const SectionBody = ({ children }) => {

    return (
        <Grid item xs={12}>
            {children}
        </Grid>
    );

}

export default SectionBody;