import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, InputAdornment } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ReactComponent as SearchIcon } from '../../assets/search.svg';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        maxWidth: 800,
        marginRight: 20,
        '&:hover $notchedOutline': {
            border: '1px solid #c3c3c3'
        }
    },
    icon: {
        marginRight: -20,
        fill: '#ed1c24',
        width: 17,
        height: 18
    },
    focused: {},
    outlinedInput: {
        '&$focused $notchedOutline': {
            border: '1px solid #c3c3c3'
        }
    },
    notchedOutline: {
        '$notchedOutline': {
            border: '1px solid #c3c3c3'
        },
        borderRadius: 26
    },
    listbox: {
        padding: 0,
        paddingLeft: 10,
        paddingRight: 10
    },
    option: {
        padding: 0
    }
}));

const HeaderSearch = ({ results, handleOnChange, handleOnSelectFromSearch, paperComponent, renderOption }) => {
    const classes = useStyles();

    return (
        <Autocomplete
            freeSolo
            className={classes.root}
            classes={{
                option: classes.option,
                listbox: classes.listbox
            }}
            options={results}
            onInputChange={handleOnChange}
            onChange={handleOnSelectFromSearch}
            getOptionLabel={(option) => option ? option.content : ""}
            renderOption={option => renderOption(option)}
            renderInput={(params) =>
                <TextField
                    {...params}
                    placeholder="Search our documentation"
                    variant="outlined"
                    size="small"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <InputAdornment>
                                <SearchIcon className={classes.icon} />
                            </InputAdornment>
                        ),
                        classes: {
                            root: classes.outlinedInput,
                            focused: classes.focused,
                            notchedOutline: classes.notchedOutline
                        }
                    }}
                />
            }
            PaperComponent={paperComponent}
        />
    );

}

export default HeaderSearch;