import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Link } from "@material-ui/core";

import Section from '../../components/Section';
import SectionHeader from '../../components/SectionHeader';
import SectionBody from '../../components/SectionBody';

import { ReactComponent as Icon } from '../../assets/support-new.svg';
import Image from '../../assets/support.svg';
//import constants from '../../main/constants';

const useStyles = makeStyles(() => ({
    content: {
        fontSize: 16,
        marginBottom: 20
    },
    topText: {
        fontFamily: 'SourceSansPro-Regular',
    },
    links: {
        fontFamily: 'SourceSansPro-SemiBold',
    },
    listItem: {
        marginBottom: 20
    },
    link: {
        color: '#ed1c24'
    }
}));

const GetSupport = ({ setPage }) => {
    const classes = useStyles();

    return (
        <Section backgroundImage={Image}>
            <SectionHeader title="Get support">
                <Icon />
            </SectionHeader>
            <SectionBody>
                <Grid container className={classes.content}>
                    <Grid item xs={12}>
                        <div>Welcome! You can raise a product request for the following products.</div>
                    </Grid>
                </Grid>
                <ul className={classes.links}>
                    <li className={classes.listItem}>
                        <Link
                            href={process.env.REACT_APP_GET_SUPPORT_DIGITAL_JOURNEY_URL}
                            className="linkText"
                            underline='none'
                            target='_blank'
                            rel='noopener noreferrer'>
                            Omnichannel
                        </Link> / <Link
                            href={process.env.REACT_APP_GET_SUPPORT_PRODUCT_CATALOG_URL}
                            className="linkText"
                            underline='none'
                            target='_blank'
                            rel='noopener noreferrer'>
                            Product Catalogue
                        </Link>
                    </li>
                    <li className={classes.listItem}>
                        <Link
                            href={process.env.REACT_APP_GET_SUPPORT_ESIGN_URL}
                            className="linkText"
                            underline='none'
                            target='_blank'
                            rel='noopener noreferrer'>
                            eSign
                        </Link>
                    </li>
                    <li className={classes.listItem}>
                        <Link
                            href={process.env.REACT_APP_GET_SUPPORT_ORDER_MANAGEMENT_URL}
                            className="linkText"
                            underline='none'
                            target='_blank'
                            rel='noopener noreferrer'>
                            Order Management
                        </Link>
                    </li>
                </ul>
            </SectionBody>
        </Section >
    );

}

export default GetSupport;
