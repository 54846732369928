import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import {Link as LinkRouter} from 'react-router-dom';
import { AppBar, Toolbar, IconButton, Link,  Button, Menu, MenuItem } from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon, ExpandLess as ExpandLessIcon, Menu as MenuIcon } from '@material-ui/icons';

// import { ReactComponent as Image } from '../assets/celfocus.png';
import mainLogo from '../assets/celfocus.png';
import constants from '../main/constants';
import Search from './Search/Search';

import HiddenMenu from './HiddenMenu';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    appBar: {
        backgroundColor: 'white !important',
        borderBottom: '1px solid #e0e0e0'
    },
    toolbar: {
        minHeight: 48
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        }
    },
    sectionHidden: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        }
    },
    headerLogo: {
        flexGrow: 1,
        paddingTop: 4,
    },
    logo: {
        minWidth: 120
    },
    menu: {
        fontFamily: 'SourceSansPro-SemiBold',
        fontSize: 16,
        textTransform: 'none',
        marginLeft: 40
    },
    menuSelected: {
        color: '#ed1c24'
    },
    menuItem: {
        fontFamily: 'SourceSansPro-Regular',
        fontSize: 14,
        color: '#333333'
    },
    arrow: {
        color: '#ed1c24'
    }
}));

const CustomMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
        borderRadius: 0
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

const Header = ({ page, setPage }) => {
    const [hiddenMoreAnchorEl, setHiddenMoreAnchorEl] = React.useState(null);
    const [anchorElDocumentation, setAnchorElDocumentation] = React.useState(null);
    const [anchorElGetSupport, setAnchorElGetSupport] = React.useState(null);
    const [anchorElGiveFeedback, setAnchorElGiveFeedback] = React.useState(null);
    const [arrowDocumentationExpandMore, setArrowDocumentationExpandMore] = React.useState(true);
    const [arrowGetSupportExpandMore, setArrowGetSupportExpandMore] = React.useState(true);
    const [arrowGetFeedbackExpandMore, setArrowGetFeedbackExpandMore] = React.useState(true);
    const classes = useStyles();
    const trigger = useScrollTrigger();

    const handleHiddenMenuOpen = event => {
        setHiddenMoreAnchorEl(event.currentTarget);
    };

    const renderToolbarHiddenMenu = () => {
        return (
            <HiddenMenu
                page={page}
                setPage={setPage}
                hiddenMoreAnchorEl={hiddenMoreAnchorEl}
                setHiddenMoreAnchorEl={setHiddenMoreAnchorEl}
            />
        );
    }

    const handleOpen = (event, setAnchorEl, setArrowExpand) => {
        setAnchorEl(event.currentTarget);
        setArrowExpand(false);
    }

    const handleClose = (setAnchorEl, setArrowExpand) => {
        setAnchorEl(null);
        setArrowExpand(true);
    }

    const getArrowExpand = (arrowExpandMore) => {
        return arrowExpandMore ? <ExpandMoreIcon className={classes.arrow} /> : <ExpandLessIcon className={classes.arrow} />
    }

    

    return (
        <div className={classes.root}>
            <AppBar position="fixed" className={classes.appBar} elevation={trigger ? 4 : 0}>
                <Toolbar className={classes.toolbar}>
                    <div className={classes.headerLogo}>
                        <LinkRouter to={"/"}>
                            <div className='mainLogoContainer'>
                            <div><img  src={mainLogo} alt="fireSpot" className="mainLogo"/>  </div>
                            <div class="mainLogoSeparator">|</div>
                            <div className='productsText'>Products</div>
                            </div>

                        </LinkRouter>
                    </div>
                    {page === constants.DOCUMENTATION_PAGE &&
                        <Search type={constants.SEARCH_HEADER} page={page} setPage={setPage} />
                    }
                    <div className={classes.sectionDesktop}>
                        <Button
                            className={arrowDocumentationExpandMore ? classes.menu : `${classes.menu} ${classes.menuSelected}`}
                            aria-controls="documentation-menu"
                            aria-haspopup="true"
                            onClick={(event) => handleOpen(event, setAnchorElDocumentation, setArrowDocumentationExpandMore)}
                            style={{ backgroundColor: 'transparent' }}>
                            Documentation {getArrowExpand(arrowDocumentationExpandMore)}
                        </Button>
                        <CustomMenu
                            id="documentation-menu"
                            anchorEl={anchorElDocumentation}
                            keepMounted
                            open={Boolean(anchorElDocumentation)}
                            onClose={() => handleClose(setAnchorElDocumentation, setArrowDocumentationExpandMore)}>
                            <MenuItem onClick={() => handleClose(setAnchorElDocumentation, setArrowDocumentationExpandMore)}>
                                <Link
                                    className={classes.menuItem}
                                    href={process.env.REACT_APP_DOCUMENTATION_URL}
                                    underline='none'
                                    target='_blank'
                                    rel='noopener noreferrer'>
                                    Omnichannel
                                </Link>
                            </MenuItem>
                            <MenuItem onClick={() => handleClose(setAnchorElDocumentation, setArrowDocumentationExpandMore)}>
                                <Link
                                    className={page === constants.ESIGN_PAGE ? `${classes.menuItem} ${classes.menuSelected}` : classes.menuItem}
                                    href={process.env.REACT_APP_DOCUMENTATION_TOOLKIT_URL}
                                    underline='none'
                                    rel='noopener noreferrer'>
                                    Spring Boot Toolkit
                                </Link>
                            </MenuItem>
                            <MenuItem onClick={() => handleClose(setAnchorElDocumentation, setArrowDocumentationExpandMore)}>
                                <Link
                                    className={page === constants.ESIGN_PAGE ? `${classes.menuItem} ${classes.menuSelected}` : classes.menuItem}
                                    href={process.env.REACT_APP_DOCUMENTATION_ESIGN_URL}
                                    underline='none'
                                    rel='noopener noreferrer'>
                                    eSign
                                </Link>
                            </MenuItem>
                            <MenuItem onClick={() => handleClose(setAnchorElDocumentation, setArrowDocumentationExpandMore)}>
                                <Link
                                    className={page === constants.ESIGN_PAGE ? `${classes.menuItem} ${classes.menuSelected}` : classes.menuItem}
                                    href={process.env.REACT_APP_DOCUMENTATION_PRODUCT_CATALOG_URL}
                                    underline='none'
                                    rel='noopener noreferrer'>
                                    Product Catalogue
                                </Link>
                            </MenuItem>
                            <MenuItem onClick={() => handleClose(setAnchorElDocumentation, setArrowDocumentationExpandMore)}>
                                <Link
                                    className={page === constants.ESIGN_PAGE ? `${classes.menuItem} ${classes.menuSelected}` : classes.menuItem}
                                    href={process.env.REACT_APP_DOCUMENTATION_ORDER_MANAGEMENT_URL}
                                    underline='none'
                                    rel='noopener noreferrer'>
                                    Order Management
                                </Link>
                            </MenuItem>
                        </CustomMenu>
                        <Button
                            className={arrowGetSupportExpandMore ? classes.menu : `${classes.menu} ${classes.menuSelected}`}
                            aria-controls="get-support-menu"
                            aria-haspopup="true"
                            onClick={(event) => handleOpen(event, setAnchorElGetSupport, setArrowGetSupportExpandMore)}
                            style={{ backgroundColor: 'transparent' }}>
                            Get support {getArrowExpand(arrowGetSupportExpandMore)}
                        </Button>
                        <CustomMenu
                            id="get-support-menu"
                            anchorEl={anchorElGetSupport}
                            keepMounted
                            open={Boolean(anchorElGetSupport)}
                            onClose={() => handleClose(setAnchorElGetSupport, setArrowGetSupportExpandMore)}>
                            <MenuItem onClick={() => handleClose(setAnchorElGetSupport, setArrowGetSupportExpandMore)}>
                                <Link
                                    className={classes.menuItem}
                                    href={process.env.REACT_APP_GET_SUPPORT_DIGITAL_JOURNEY_URL}
                                    underline='none'
                                    target='_blank'
                                    rel='noopener noreferrer'>
                                    Omnichannel
                                </Link>
                            </MenuItem>
                            <MenuItem onClick={() => handleClose(setAnchorElGetSupport, setArrowGetSupportExpandMore)}>
                               <Link
                                    className={classes.menuItem}
                                    href={process.env.REACT_APP_GET_SUPPORT_ESIGN_URL}
                                    underline='none'
                                    target='_blank'
                                    rel='noopener noreferrer'>
                                    eSign
                                </Link>
                            </MenuItem>
                            <MenuItem onClick={() => handleClose(setAnchorElGetSupport, setArrowGetSupportExpandMore)}>
                                <Link
                                    className={classes.menuItem}
                                    href={process.env.REACT_APP_GET_SUPPORT_PRODUCT_CATALOG_URL}
                                    underline='none'
                                    target='_blank'
                                    rel='noopener noreferrer'>
                                    Product Catalogue
                                </Link>
                            </MenuItem>
                            <MenuItem onClick={() => handleClose(setAnchorElGetSupport, setArrowGetSupportExpandMore)}>
                                <Link
                                    className={classes.menuItem}
                                    href={process.env.REACT_APP_GET_SUPPORT_ORDER_MANAGEMENT_URL}
                                    underline='none'
                                    target='_blank'
                                    rel='noopener noreferrer'>
                                    Order Management
                                </Link>
                            </MenuItem>
                        </CustomMenu>
                        <Button
                            className={arrowGetFeedbackExpandMore ? classes.menu : `${classes.menu} ${classes.menuSelected}`}
                            aria-controls="feedback-menu"
                            aria-haspopup="true"
                            onClick={(event) => handleOpen(event, setAnchorElGiveFeedback, setArrowGetFeedbackExpandMore)}
                            style={{ backgroundColor: 'transparent' }}>
                            Give feedback {getArrowExpand(arrowGetFeedbackExpandMore)}
                        </Button>
                        <CustomMenu
                            id="feedback-menu"
                            anchorEl={anchorElGiveFeedback}
                            keepMounted
                            open={Boolean(anchorElGiveFeedback)}
                            onClose={() => handleClose(setAnchorElGiveFeedback, setArrowGetFeedbackExpandMore)}>
                            <MenuItem onClick={() => handleClose(setAnchorElGiveFeedback, setArrowGetFeedbackExpandMore)}>
                                <Link
                                    className={classes.menuItem}
                                    href={process.env.REACT_APP_FEEDBACK_DIGITAL_JOURNEY_URL}
                                    underline='none'
                                    target='_blank'
                                    rel='noopener noreferrer'>
                                    Omnichannel
                                </Link>
                            </MenuItem>
                            <MenuItem onClick={() => handleClose(setAnchorElGiveFeedback, setArrowGetFeedbackExpandMore)}>
                                <Link
                                    className={classes.menuItem}
                                    href={process.env.REACT_APP_FEEDBACK_ESIGN_URL}
                                    underline='none'
                                    target='_blank'
                                    rel='noopener noreferrer'>
                                    eSign
                                </Link>
                            </MenuItem>
                            <MenuItem onClick={() => handleClose(setAnchorElGiveFeedback, setArrowGetFeedbackExpandMore)}>
                                <Link
                                    className={classes.menuItem}
                                    href={process.env.REACT_APP_FEEDBACK_PRODUCT_CATALOG_URL}
                                    underline='none'
                                    target='_blank'
                                    rel='noopener noreferrer'>
                                    Product Catalgoue
                                </Link>
                            </MenuItem>
                            <MenuItem onClick={() => handleClose(setAnchorElGiveFeedback, setArrowGetFeedbackExpandMore)}>
                                <Link
                                    className={classes.menuItem}
                                    href={process.env.REACT_APP_FEEDBACK_ORDER_MANAGEMENT_URL}
                                    underline='none'
                                    target='_blank'
                                    rel='noopener noreferrer'>
                                    Order Management
                                </Link>
                            </MenuItem>
                        </CustomMenu>
                    </div>
                    <div className={classes.sectionHidden}>
                        <IconButton
                            onClick={handleHiddenMenuOpen}>
                            <MenuIcon />
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>
            { renderToolbarHiddenMenu()}
        </div >

    );

}

export default Header;