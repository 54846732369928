import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link, Menu, MenuItem } from '@material-ui/core';
import constants from '../main/constants';

const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiPaper-root": {
            borderRadius: 0
        }
    },
    menu: {
        fontFamily: 'SourceSansPro-Regular',
        fontSize: 14,
        textTransform: 'none',
        textDecoration: 'none',
        color: '#333333'
    },
    subMenu: {
        marginLeft: 20
    },
    menuSelected: {
        color: '#ed1c24'
    },
    menuItem: {
        fontFamily: 'SourceSansPro-Regular',
        fontSize: 14,
        color: '#333333'
    }
}));

const HiddenMenu = ({ page, setPage, hiddenMoreAnchorEl, setHiddenMoreAnchorEl }) => {
    const isHiddenMenuOpen = Boolean(hiddenMoreAnchorEl);
    const classes = useStyles();

    const handleHiddenMenuClose = () => {
        setHiddenMoreAnchorEl(null);
    };

    return (
        <Menu
            className={classes.root}
            anchorEl={hiddenMoreAnchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isHiddenMenuOpen}
            onMouseLeave={handleHiddenMenuClose}
            onClose={handleHiddenMenuClose}
            disableEnforceFocus={true}>
            {/*<MenuItem>
                Getting started
            </MenuItem>
            <MenuItem
                onClick={() => {
                    handleHiddenMenuClose();
                }}>
                <div className={page === constants.SETUP_ENVIRONMENT_PAGE ? `${classes.menu} ${classes.subMenu} ${classes.menuSelected}` : classes.subMenu}>Setup environment</div>
            </MenuItem>
            <MenuItem
                onClick={() => {
                    handleHiddenMenuClose();
                }}>
                <div className={page === constants.TUTORIALS_PAGE ? `${classes.menu} ${classes.subMenu} ${classes.menuSelected}` : classes.subMenu}>Tutorials</div>
            </MenuItem>
            <MenuItem
                onClick={() => {
                    handleHiddenMenuClose();
                }}>
                <div className={page === constants.LEARNING_PATHS_PAGE ? `${classes.menu} ${classes.subMenu} ${classes.menuSelected}` : classes.subMenu}>Learning Paths</div>
            </MenuItem>*/}
            <MenuItem
                className={page === constants.DOCUMENTATION_PAGE ? `${classes.menu} ${classes.menuSelected}` : classes.menu}
                onClick={() => {
                    handleHiddenMenuClose();
                    setPage(constants.DOCUMENTATION_PAGE);
                }}>
                Documentation
            </MenuItem>
            <MenuItem
                className={page === constants.RELEASE_PLAN_PAGE ? `${classes.menu} ${classes.menuSelected}` : classes.menu}
                onClick={() => {
                    handleHiddenMenuClose();
                    setPage(constants.RELEASE_PLAN_PAGE);
                }}>
                Release plan
            </MenuItem>
            <MenuItem className={classes.menu}>
                Give feedback
            </MenuItem>
            <MenuItem onClick={handleHiddenMenuClose}>
                <Link
                    className={classes.menuItem}
                    href={process.env.REACT_APP_FEEDBACK_DIGITAL_JOURNEY_URL}
                    underline='none'
                    target='_blank'
                    rel='noopener noreferrer'>
                    <div className={`${classes.menu} ${classes.subMenu}`}>Digital Journey</div>
                </Link>
            </MenuItem>
            <MenuItem onClick={handleHiddenMenuClose}>
                <Link
                    className={classes.menuItem}
                    href={process.env.REACT_APP_FEEDBACK_ESIGN_URL}
                    underline='none'
                    target='_blank'
                    rel='noopener noreferrer'>
                    <div className={`${classes.menu} ${classes.subMenu}`}>eSign</div>
                </Link>
            </MenuItem>
            <MenuItem onClick={handleHiddenMenuClose}>
                <Link
                    className={classes.menuItem}
                    href={process.env.REACT_APP_FEEDBACK_PRODUCT_CATALOG_URL}
                    underline='none'
                    target='_blank'
                    rel='noopener noreferrer'>
                    <div className={`${classes.menu} ${classes.subMenu}`}>Product Catalog</div>
                </Link>
            </MenuItem>
        </Menu >
    );

}

export default HiddenMenu;