import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import './PaperItem.css';
import Chip from "@material-ui/core/Chip";
import { Paper } from '@material-ui/core';
import { ReactComponent as NewsIcon } from '../assets/news-icon.svg';
import WatchLaterIcon from '@material-ui/icons/WatchLater';

const useStyles = makeStyles({
    comingSoonChip: {
        backgroundColor: '#1f96a2',
        color: 'white',
        height: 16,
        fontSize: 10,
        paddingTop: 1,
        marginLeft: 4
    }
})

function PaperItem(props) {
    const classes = useStyles();

    return (
        <Paper elevation={3} className="paperRectangle">
            <div className="container paperCard">
                <div className="paperCardIcon">
                    <NewsIcon/>
                </div>
                <div className="paperCardText">
                    <div>
                        {props.url &&
                            <a target="_blank" rel="noopener noreferrer"
                                className="paperLinkText" href={props.url}>
                                {props.title}
                            </a>
                        }
                        {!props.url &&
                            <div className="paperLinkText">
                                {props.title}
                            </div>
                        }
                    </div>
                    {props.description &&
                        <div className="paperDescription">
                            {props.description}
                        </div>
                    }
                </div>
            {props.active && props.time &&
                <div className="paperCardChip">
                    <Chip size="small" className="paperChip" avatar={<WatchLaterIcon className="watchFace" />} label={props.time}></Chip>
                </div>
            }
            {!props.active && 
                <div className="paperCardChip">
                    <Chip size="small" label="COMING SOON" className={classes.comingSoonChip} />
                </div>
            }
            </div>
        </Paper>

    )
}
export default PaperItem;
