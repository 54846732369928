import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid} from "@material-ui/core";
//import {TextField, InputAdornment } from "@material-ui/core";
//import Autocomplete from '@material-ui/lab/Autocomplete';
//import { ReactComponent as SearchIcon } from '../../assets/search.svg';

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
        paddingTop: 50,
        paddingBottom: 40,
        width: 'calc(100% - 16px)',
        margin: 0
    },
    title: {
        fontFamily: 'Gotham-Book',
        fontSize: 32,
        letterSpacing: -1,
        color: 'white',
        padding: '0px 20px 10px 20px'
    },
    textField: {
        fontFamily: 'SourceSansPro-Regular',
        fontSize: 16,
        backgroundColor: 'white',
        maxWidth: 864,
        marginLeft: 20,
        marginRight: 20,
        height: 52,
        borderRadius: 26
    },
    searchIcon: {
        fill: '#ed1c24',
        width: 17,
        height: 18
    },
    focused: {},
    outlinedInput: {
        '&$focused $notchedOutline': {
            border: '0px'
        },
    },
    notchedOutline: {
        '&$notchedOutline': {
            border: '0px'
        }
    },
    listbox: {
        padding: 0,
        paddingLeft: 10,
        paddingRight: 10
    },
    option: {
        padding: 0
    }
}));

const MainSearch = ({ results, handleOnChange, handleOnSelectFromSearch, paperComponent, renderOption }) => {
    const classes = useStyles();

    return (
        <Grid container className={classes.root} spacing={2}>
            <Grid item xs={12}>
                <Grid container justify="center" className={classes.title}>
                    <b>Welcome!</b>&nbsp; How can we help you?
                </Grid>
            </Grid>
        </Grid>
    );

}

export default MainSearch;