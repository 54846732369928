import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Chip from "@material-ui/core/Chip";
import { Link } from 'react-router-dom';

import Section from '../../components/Section';
import SectionHeader from '../../components/SectionHeader';
import SectionBody from '../../components/SectionBody';

import { ReactComponent as Icon } from '../../assets/start-new.svg';
import Image from '../../assets/start.svg';
//import constants from '../../main/constants';

const useStyles = makeStyles(() => ({
    content: {
        fontSize: 16,
        marginBottom: 20
    },
    list: {
        fontFamily: 'SourceSansPro-Regular',
        fontSize: 16,
        marginTop: 0
    },
    listItem: {
        marginBottom: 20
    },
    chip: {
        backgroundColor: '#2598A3',
        color: 'white',
        height: 16,
        fontSize: 10,
        paddingTop: 1,
        marginLeft: 4
    },
    chipNew: {
        backgroundColor: '#8b68ea',
        color: 'white',
        height: 16,
        fontSize: 10,
        paddingTop: 1,
        marginLeft: 4
    },
    link: {
        color: '#1f96a2',
        cursor: 'pointer'
    },
    a: {
        color: '#1f96a2',
        cursor: 'pointer'
    }
}));

const GettingStarted = ({ setPage }) => {
    const classes = useStyles();

    return (
        <Section backgroundImage={Image}>
            <SectionHeader title="Getting started">
                <Icon />
            </SectionHeader>
            <SectionBody>
                <ul className={classes.list}>
                    <li className={classes.listItem}>
                        <Link to={"/training"} className="linkText" underline='none'>Training</Link>
                    </li>
                    <li className={classes.listItem}>
                        <a href='/docs/omnichannel/omnichannel/3.4.0/installguide/introduction.html' target='_blank' className="linkText" underline='none' rel='noopener noreferrer'>Setup Environment</a> 
                        {/* <Chip size="small" label="NEW" className={classes.chipNew} /> */}
                    </li>
                    <li className={classes.listItem}>
                        <a href='/docs/omnichannel/omnichannel/3.4.0/tutorials/start-developing.html' target='_blank' className='linkText' underline='none' rel='noopener noreferrer'>Tutorials</a> <Chip size="small" label="NEW" className={classes.chipNew} />
                    </li>
                    {/* <li className={classes.listItem}>Learning Paths <Chip size="small" label="COMING SOON" className={classes.chip} /></li> */}
                    {/* <li className={classes.listItem}>Backend IDE&nbsp;
                    <a href={process.env.REACT_APP_BACKEND_IDE_WINDOWS_URL} className="linkText" rel="noopener noreferrer" underline='none' target='_blank'>Windows</a> /&nbsp;
                    <a href={process.env.REACT_APP_BACKEND_IDE_MACOS_URL} className="linkText" rel="noopener noreferrer" underline='none' target='_blank'>Mac OS</a> /&nbsp;
                    <a href={process.env.REACT_APP_BACKEND_IDE_LINUX_URL} className="linkText" rel="noopener noreferrer" underline='none' target='_blank'>Linux</a></li>
                    <li>
                        <Link to={"/distrobuilder"} className="linkText" underline='none'>Distribution Builder</Link>
                    </li> */}
                </ul>
            </SectionBody>
        </Section>
    );

}

export default GettingStarted;
