import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles(() => ({
    root: {
        alignContent: 'flex-start',
        height: '100%',
        width: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right bottom',
        padding: 32
    }
}));

const Section = ({ children, backgroundImage }) => {

    const classes = useStyles();

    return (
        <Grid container
            className={classes.root}
            style={{ backgroundImage: backgroundImage ? `url(${backgroundImage})` : null }}>
            {children}
        </Grid>
    );

}

export default Section;